<template>
  <div class="">
    <SiteNav />

    <section class="hero mt-8 lg:my-12">
      <div
        id="img"
        style="border-radius: 60px"
        class="container overflow-hidden h-80 bg-contain no-repeat w-full l-container p-5"
      >
        <!-- <div class="overlay"></div> -->
        <div class="side-box pl-7 pt-7">
          <div style="background-color: #f5d5d2" class="rounded-full w-32 p-2 text-center text-sm text-primary-deep">
            #EazipayCares
          </div>

          <h1 class="md:block hidden mt-5 leading-10 head-font text-white">
            Supporting
            <span class="text-mint"
              >Businesses & <br />
              Employees</span
            >
            through tough <br />
            times
          </h1>

          <h1 class="lg:hidden mt-5 leading-10 head-font text-white">
            Supporting
            <span class="text-mint">Businesses & Employees</span>
            through tough times
          </h1>

          <h4 class="mt-3 text-sm text-white">Together, we can weather any storm.</h4>
        </div>
        <img
          class="hidden md:block absolute object-cover top-0 right-0 h-full w-6/12"
          src="./assets/team-pic.png"
          alt=""
        />
      </div>
    </section>

    <section class="w-full md:-mt-10 lg:-mt-0 bg-mint flex flex-col gap-6 pt-16 pb-12">
      <div class="l-container relative w-full">
        <h2 class="text-center text-primary-deep font-bold md:text-3xl text-xl">
          At Eazipay, we understand the challenges businesses and employees <br />
        </h2>
        <h2 class="text-primary-deep font-bold md:text-3xl text-xl text-center">are facing right now</h2>

        <p class="text-center mt-4 text-sm md:text-base text-primary-deep">
          That's why we're launching the #EazipayCares campaign—to provide support, relief, and a helping hand when it's
          needed most.
        </p>

        <img src="./assets/arrow.png" class="hidden lg:block absolute left-16 w-20 top-16" alt="" />
      </div>
    </section>

    <section class="">
      <div class="w-full l-container">
        <div
          class="mt-9 mb-6 md:mt-20 md:mb-10 py-2 mx-auto justify-center cursor-pointer flex items-center gap-2 px-2 w-9/12 md:w-64 bg-white rounded-full shadow-lg"
        >
          <div
            @click="switchView"
            class="cursor-pointer"
            :class="
              bool
                ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                : 'bg-white text-center text-dark-800 text-sm md:text-sm w-full '
            "
          >
            For Businesses
          </div>
          <div
            @click="switchView"
            class="cursor-pointer"
            :class="
              !bool
                ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                : 'bg-white text-dark-800 text-sm md:text-sm w-full '
            "
          >
            For Employees
          </div>
        </div>
      </div>
      <div class="relative promo mt-12 overflow-hidden bg-mint w-full l-container">
        <div class="z-50 px-4 md:px-10">
          <h3 v-if="bool" class="absolute top-0 my-10 font-bold text-white text-2xl">
            Promo eligibility for <br />
            Businesses
          </h3>

          <h3 v-else class="absolute top-0 my-10 font-bold text-white text-2xl">
            Promo eligibility for <br />
            Employees & Income Earners
          </h3>

          <LearnAccordion :bool="bool" class="mt-24" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { watch, ref, onMounted } from "vue";
import LearnAccordion from "@/www/components/LearnAccordion.vue";

export default {
  data() {
    return {
      hoverName: "",
      bool: true,
    };
  },
  components: { LearnAccordion },
  methods: {
    async scrollToSection() {
      // Get a reference to the section element
      const section = this.$refs.calculator;

      // Ensure the section element exists
      if (section) {
        // Scroll to the section using scrollIntoView

        await this.$nextTick(() => section.scrollIntoView({ behavior: "smooth", block: "center" }));
        // this.$nextTick(() => section.scrollIntoView({ behavior: "smooth" }));
      }
    },
    switchView() {
      this.bool = !this.bool;
    },
  },
  async mounted() {
    this.scrollItem = true;
    // Call scrollToSection when the component is mounted
    // this.scrollToSection();
    await this.$nextTick();

    if (this.$route.query.scroll) {
      this.scrollToSection();
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

// .img {
//   background-image: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 0.1) rgba(17, 69, 59, 1)),
//     url("./assets/team-pic.png");
// }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.head-font {
  font-weight: 500;
  line-height: 1.1;
  font-family: "MADE Mellow", sans-serif;
  font-size: 36px;

  &.level-1 {
    font-size: 32px;
  }

  &.level-2 {
    font-size: 35px;
  }

  &.level-3 {
    font-size: 25px;
  }
}

.promo {
  background: url("./assets/eligibility-img.png");
  background-repeat: no-repeat;
  background-size: auto 120%;
  background-position-x: center;
  background-position-y: top;
  border-radius: 20px;
  padding: 40px 0 30px 0;
  max-height: 900px;
  overflow: auto;
}
.promo::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  min-height: 800px;

  background: linear-gradient(to top right, rgba(25, 25, 25, 1), rgba(22, 23, 22, 0.36));
}
.text {
  opacity: 0;
}

.image-container {
  transition: all 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}
.image-container:hover .text {
  opacity: 1;
}
.round {
  border-top-left-radius: 80px !important;
}
.hero {
  .container {
    position: relative;
    // width: 100%;
    // height: 100vh;

    overflow: hidden;
  }
  .side-box {
    position: absolute;
    top: 3px;
    left: 20px;
    border-radius: 20px;
    border: 0.5px solid rgba(57, 109, 99, 0.8);
    box-sizing: border-box;
    // box-shadow: 0 11.96px 23.92px 0 rgba(9, 59, 49, 0.25);

    width: 90%;
    height: auto;
    padding-bottom: 20px;
    background: #11453b;
    overflow: hidden;
  }
  .heading {
    color: #11453b;
  }
  #img {
    position: relative;
    overflow: hidden;
    border-radius: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    // background: url("./assets/team-pic.png");
    //background: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 1));
  }
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    opacity: 0.6;
    background: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 0.1) rgba(17, 69, 59, 1));
  }
}

.features-section {
  .feature-list {
    display: grid;
    grid-gap: 80px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 28px;

      .media {
        max-width: 300px;
        flex-shrink: 0;
      }

      .content {
        max-width: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        font-size: 30px;
        margin-bottom: 16px;
      }
    }
  }

  li.compliance {
    margin-top: -25px; /* to reconcile the spacing at the top of the image */
  }
}

.payroll-calculator-section {
  background-color: #f0f7eb;
}

@screen lg {
  .hero {
    .container {
      position: relative;
      // width: 100%;
      // height: 100vh;
      background: linear-gradient(
        to right,
        rgba(17, 69, 59, 1),
        40%,
        rgba(17, 69, 59, 0.7),
        rgba(17, 69, 59, 0.1),
        rgba(255, 255, 255, 0)
      );
      overflow: hidden;
    }
    .side-box {
      position: absolute;
      top: 30px;
      left: 30px;
      border-radius: 32px;
      border: 0.5px solid rgba(57, 109, 99, 0.8);
      box-sizing: border-box;
      box-shadow: 0 11.96px 23.92px 0 rgba(9, 59, 49, 0.25);

      width: 53%;
      height: auto;
      padding-bottom: 20px;
      z-index: 50;
      background: linear-gradient(to bottom, rgba(17, 69, 59, 1), rgba(42, 171, 146, 0));
      overflow: hidden;
    }
  }
  .promo {
    background: url("./assets/eligibility-img.png");
    background-repeat: no-repeat;
    background-size: auto 120%;
    background-position-x: right;
    background-position-y: top;
    border-radius: 40px;
    height: 800px;
    padding: 40px 0 30px 0;
    overflow: auto;
  }
  .promo::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0 !important;
    width: 100%;
    min-height: 100%;

    background: linear-gradient(to top right, rgba(25, 25, 25, 1), rgba(22, 23, 22, 0.36));
  }
  .features-section {
    .feature-list {
      grid-gap: 100px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;

        .media {
          width: 45%;
          max-width: unset;
          flex-shrink: 0;
        }

        .content {
          width: 50%;
          max-width: unset;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
        .title {
          font-size: 50px;
          margin-bottom: 16px;
        }
      }

      li.reverse {
        flex-direction: row-reverse;
      }
      li.compliance {
        margin-top: 0px; /* to */
      }
    }
  }
}
</style>
